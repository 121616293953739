import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'uat-hotfix',

  ping: {
    clientId: '4d3b2aff-26c3-4b13-9641-696b37c765e0',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  ecomm: {
    baseUrl: 'https://ecomm.hotfix.us.regional.uat.cf.10piececombo.com',
    menuUrl: 'https://ecomm.hotfix.us.regional.uat.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.hotfix.us.regional.uat.cf.10piececombo.com'
  },
  ngfeBaseUrl: 'https://hotfix.uat.ordering.wingstop.com',
};
